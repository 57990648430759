import React from "react";
import { useSelector } from "react-redux";
// reactstrap components
import { Button, Container, Row, Col, Media } from "reactstrap";
import { getAdminData } from "redux/slices/adminSlice";
import { handleBalanceVND } from "utils/helper";
import NoLogo from "../../assets/img/no-logo.png";

const HeaderCompany = (props) => {
  let userAdmin = useSelector(getAdminData);
  return (
    <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
      <Container fluid>
        <div className="header-body">
          <Row className="align-items-center py-4">
            <Col lg="10" xs="12" className="header-main d-flex align-items-center">
                <span className="avatar avatar-header avatar-xl rounded-circle mr-3 xs">
                  <img
                    alt={`Logo ${userAdmin.name}`}
                    src={userAdmin.logo != "" ? userAdmin.logo : NoLogo}
                  />
                </span>
              <div className="header-personal mr-5">
                <span className="mb-0 text-xs font-weight-100">XIN CHÀO</span>
                <span className="mb-0 text-xl font-weight-bold">
                  {userAdmin.name}
                </span>
                <span className="mb-0 text-sm font-weight-100">
                  <i>
                    Đăng nhập lần cuối vào{" "}
                    {new Date(userAdmin.last_login).toLocaleString("fr-BE")}
                  </i>
                </span>
              </div>
              <div className="header-personal">
                <span className="mb-0 text-xs font-weight-100">
                  Số dư khả dụng
                </span>
                <span className="mb-0 text-xl font-weight-bold">
                  {handleBalanceVND(userAdmin.balance)}
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default HeaderCompany;
