import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import adminReducer from "./slices/adminSlice";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["error_login", "status_login"],
};

const rootReducer = combineReducers({
  admin: persistReducer(rootPersistConfig, adminReducer),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
