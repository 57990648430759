import classnames from "classnames";
import AuthHeader from "components/Headers/AuthHeader.js";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  FormFeedback,
  Row,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { loginAdmin } from "redux/slices/adminSlice";
import { getAdminStatus, getAdminErr } from "redux/slices/adminSlice";
import ToastProvider from "hooks/useToastProvider";

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email không đúng format")
    .required("Email không được để trống"),
  password: Yup.string()
    .required("Mật khẩu không được để trống")
    .min(3, "Mật khẩu phải có ít nhất 3 ký tự."),
});

const Login = () => {
  const dispatch = useDispatch();
  const status_login = useSelector(getAdminStatus);
  const error_login = useSelector(getAdminErr);
  const [state, setState] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });

  const handleLoginUser = (props) => {
    const { email, password } = props;
    dispatch(
      loginAdmin({
        email: email,
        password: password,
      })
    );
  };

  const registerRs = (fieldName, options = {}) => {
    const registeredField = register(fieldName, options);
    const ref = registeredField.ref;
    delete registeredField.ref;
    return { ...registeredField, innerRef: ref };
  };

  useEffect(() => {
    if (status_login === "success")
      ToastProvider("success", "Đăng nhập thành công");
    if (status_login === "failed" && error_login)
      ToastProvider("error", error_login);
  }, [dispatch, status_login, error_login]);

  return (
    <>
      <AuthHeader image={true} />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Sign in with credentials</small>
                </div>
                <Form role="form">
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: state.focusedEmail,
                    })}
                  >
                    <InputGroup
                      className={`input-group-merge input-group-alternative ${
                        errors.email ? "is-invalids" : ""
                      }`}
                    >
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                      <Input
                        {...registerRs("email")}
                        placeholder="email"
                        type="email"
                        onFocus={() => setState({ focusedEmail: true })}
                        onBlur={() => setState({ focusedEmail: false })}
                        invalid={errors.email ? true : false}
                      />
                    </InputGroup>
                    <FormFeedback>{errors?.email?.message}</FormFeedback>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: state.focusedPassword,
                    })}
                  >
                    <InputGroup
                      className={`input-group-merge input-group-alternative ${
                        errors.password ? "is-invalids" : ""
                      }`}
                    >
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                      <Input
                        {...registerRs("password")}
                        placeholder="Password"
                        type="password"
                        onFocus={() => setState({ focusedPassword: true })}
                        onBlur={() => setState({ focusedPassword: false })}
                        invalid={errors.password ? true : false}
                      />
                    </InputGroup>
                    <FormFeedback>{errors?.password?.message}</FormFeedback>
                  </FormGroup>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="info"
                      type="button"
                      onClick={handleSubmit(handleLoginUser)}
                    >
                      Sign in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
