import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginAdminAPI } from "../../components/api/admin";

export const loginAdmin = createAsyncThunk(
  "loginAdmin",
  async ({ email, password }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const props = { email, password };
      const response = await loginAdminAPI(props);
      console.log("response", response);
      if (response.status == 1) {
        return fulfillWithValue(response.info);
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      throw rejectWithValue(err.response.message);
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState: { status_login: "idle", adminData: null, error_login: null },
  reducers: {
    replaceUser: (state, action) => {
      const newUserData = action.payload;
      state.adminData = newUserData;
    },
    collapeCollector: (state, action) => {
      state.isCollector = true;
    },
    logout: (state, action) => {
      state.adminData = null;
      state.status_login = "idle";
    },
  },
  extraReducers(builder) {
    builder
      // Login
      .addCase(loginAdmin.pending, (state, action) => {
        state.status_login = "loading";
      })
      .addCase(loginAdmin.fulfilled, (state, action) => {
        state.status_login = "success";
        state.adminData = action.payload;
      })
      .addCase(loginAdmin.rejected, (state, action) => {
        state.status_login = "failed";
        state.error_login = action.payload;
      });
  },
});

//Reducers
export const { replaceUser, logout } = adminSlice.actions;

// actions
export const getAdminData = (state) => state.admin?.adminData;
export const getAdminStatus = (state) => state.admin?.status_login;
export const getAdminErr = (state) => state.admin?.error_login;

// reducer
export default adminSlice.reducer;
