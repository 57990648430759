import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
  FormFeedback
} from "reactstrap";
// core components
import HeaderCompany from "components/Headers/HeaderCompany.js";
import ToastProvider from "hooks/useToastProvider";
import Api from "components/api/api";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";
import { replaceUser } from "redux/slices/adminSlice";

const formThongTinSchema = Yup.object().shape({
  name: Yup.string()
    .required("Tên không được để trống"),
  phone: Yup.string()
    .required("Số điện thoại không được để trống")
});

const HomeInformation = () => {

  const dispatch = useDispatch();
  
  let userAdmin = useSelector(getAdminData);
  const [isLoading, setIsLoading] = useState(false);
  const [stringAlert, setStringAlert] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password_current, setPasswordCurrent] = useState("");
  const [password_new, setPasswordNew] = useState("");
  const [password_new_re, setPasswordNewRe] = useState("");
  
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formThongTinSchema),
  });

  const registerRs = (fieldName, options = {}) => {
    const registeredField = register(fieldName, options);
    const ref = registeredField.ref;
    delete registeredField.ref;
    return { ...registeredField, innerRef: ref };
  };

  useEffect(()=>{
    loadInfoAccount();
  }, []);

  const  loadInfoAccount = async ()=>{
    const reqUrl = "/partner/get-info-account";
    const params = {token_login:userAdmin.token_login, email:userAdmin.email};
    const res = await Api.post(reqUrl, params);
    if(res.data.status == true)
    {
      console.log("loadInfoAccount", res.data);
      dispatch(replaceUser(res.data.info));
    }
  }

  const handleSubmitFormThongTin = async (value, e) => {
    console.log("handleSubmitFormThongTin");
    e.preventDefault();
    let isPost = true;

    if(password_current != "")
    {
      if(password_new != password_new_re)
      {
        isPost = false;
        ToastProvider("error", "Nhập lại mật khẩu mới không chính xác");
      }
    }
    if(isPost)
    {
      setIsLoading(true);
      const reqUrl = "/partner/update-info-account";
      const params = {};
      params.name = name;
      params.phone = phone;
      params.token_login = userAdmin.token_login;
      params.email = userAdmin.email;
      if(password_new != "")
      {
        params.password_current = password_current;
        params.password_new = password_new;
      }
      const res = await Api.post(reqUrl, params);
      console.log("handleSubmitForm", res, res.data);
      if(res.data.status)
      {
        ToastProvider("success", "Cập nhật thành công");
        setIsLoading(false);
        dispatch(replaceUser(res.data.info));
      }
      else
      {
        ToastProvider("error", res.data.message);
        setStringAlert(res.data.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <HeaderCompany name="Thông tin tài khoản" parentName="Thông tin tài khoản" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Thông tin tài khoản</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="name"
                      >
                        Tên tài khoản
                      </label>
                      <Input
                        {...registerRs("name")}
                        defaultValue={userAdmin.name}
                        name="name"
                        type="text"
                        className={`${errors.name ? "isError" : ""}`}
                        onChange={(e) => setName(e.target.value)}
                        invalid={errors.name ? true : false}
                      />
                      <FormFeedback>{errors?.name?.message}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="phone"
                      >
                        Số điện thoại
                      </label>
                      <Input
                      {...registerRs("phone")}
                        id="phone"
                        placeholder="Điện thoại"
                        type="text"
                        defaultValue={userAdmin.phone}
                        className={`${errors.phone ? "isError" : ""}`}
                        onChange={(e) => setPhone(e.target.value)}
                        invalid={errors.phone ? true : false}
                      />
                      <FormFeedback>{errors?.phone?.message}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <Input
                        id="email"
                        placeholder="name@example.com"
                        type="email"
                        defaultValue={userAdmin.email}
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="password_current"
                      >
                        Mật khẩu hiện tại
                      </label>
                      <Input
                        name="password_current"
                        id="password_current"
                        placeholder="Mật khẩu hiện tại"
                        type="password"
                        defaultValue=""
                        onChange={(e) => setPasswordCurrent(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="password_new"
                      >
                        Mật khẩu mới
                      </label>
                      <Input
                        name="password_new"
                        id="password_new"
                        placeholder="Mật khẩu mới"
                        type="password"
                        defaultValue=""
                        onChange={(e) => setPasswordNew(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="password_new_re"
                      >
                        Nhập lại mật khẩu mới
                      </label>
                      <Input
                        name="password_new_re"
                        id="password_new_re"
                        placeholder="Nhập lại mật khẩu mới"
                        type="password"
                        defaultValue=""
                        onChange={(e) => setPasswordNewRe(e.target.value)}
                      />
                    </FormGroup>
                    <div className="mt-3 d-flex d-text align-items-md-end justify-content-md-end">
                    {isLoading ? (
                        <p>{stringAlert ? stringAlert : "Đang xử lý"}</p>
                      ) : (
                      <Button
                        className="btn-icon"
                        color="primary"
                        type="button"
                        onClick={handleSubmit(handleSubmitFormThongTin)}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-bag-17" />
                        </span>
                        <span className="btn-inner--text">Lưu</span>
                      </Button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Thông tin công ty và xuất hoá đơn</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company_name"
                        >
                          Tên công ty
                        </label>
                        <Input
                          id="company_name"
                          placeholder="Tên công ty"
                          type="text"
                          defaultValue={userAdmin.company_name}
                          readOnly
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company_tax"
                        >
                          Mã số thuế
                        </label>
                        <Input
                          id="company_tax"
                          placeholder="Mã số thuế"
                          type="text"
                          defaultValue={userAdmin.company_tax}
                          readOnly
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company_address"
                        >
                          Địa chỉ
                        </label>
                        <Input
                          id="company_address"
                          placeholder="Địa chỉ"
                          type="text"
                          defaultValue={userAdmin.company_address}
                          readOnly
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company_business_type"
                        >
                          Loại hình kinh doanh
                        </label>
                        <Input
                          id="company_business_type"
                          placeholder="Loại hình kinh doanh"
                          type="text"
                          defaultValue={userAdmin.company_business_type}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company_representative"
                        >
                          Người đại diện
                        </label>
                        <Input
                          id="company_representative"
                          placeholder="Người đại diện"
                          type="text"
                          defaultValue={userAdmin.company_representative}
                          readOnly
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company_representative_title"
                        >
                          Chức vụ
                        </label>
                        <Input
                          id="company_representative_title"
                          placeholder="Chức vụ"
                          type="text"
                          defaultValue={userAdmin.company_representative_title}
                          readOnly
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company_representative_birthday"
                        >
                          Ngày sinh
                        </label>
                        <Input
                          id="company_representative_birthday"
                          placeholder="Ngày sinh"
                          type="text"
                          defaultValue={userAdmin.company_representative_birthday}
                          readOnly
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company_representative_cccd"
                        >
                          Số CMND/CCCD/Hộ chiếu
                        </label>
                        <Input
                          id="company_representative_cccd"
                          placeholder="Số CMND/CCCD/Hộ chiếu"
                          type="text"
                          defaultValue={userAdmin.company_representative_cccd}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default HomeInformation;
