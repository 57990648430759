import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class Calendar extends React.Component {
  render() {
    return (
      <>
        <Container fluid>
          <footer className="footer pt-0">
            <Row className="align-items-center justify-content-lg-between">
              <Col lg="6">
                <div className="copyright text-center text-xl-left text-muted">
                  © {new Date().getFullYear()}{" "}
                  <a
                    className="font-weight-bold ml-1"
                    href="https://codev.vn/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Codev Service Co.,Ltd
                  </a>
                </div>
              </Col>
              <Col lg="6">
                <Nav className="nav-footer justify-content-center justify-content-lg-end">
                  <NavItem>
                    <NavLink
                      href="https://www.facebook.com/codev.vn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://www.youtube.com/channel/UCVVxoP3YgM-8mqA8DJVvluQ"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Youtube
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="tel:0944223879" target="_blank" rel="noreferrer">
                      Hotline: 0944223879
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </footer>
        </Container>
      </>
    );
  }
}

export default Calendar;
