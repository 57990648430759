import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import HeaderCompany from "components/Headers/HeaderCompany.js";
// react plugin that creates text editor
import Dropzone from "dropzone";
import {handleBalanceVND} from "../../../utils/helper";
import { getAdminData } from "redux/slices/adminSlice";
import { useSelector } from "react-redux";

Dropzone.autoDiscover = false;

const Payment = () => {
  let userAdmin = useSelector(getAdminData);
  const [money, setMoney] = useState(100000);
  const [bank, setBank] = useState(null);

  useEffect(()=>{
    if(userAdmin.company_tax != "")
    {
    setBank({name:"Ngân hàng TMCP Sài Gòn Thương Tín",
    bin:"970403",
    accountNumber:"060132899579",
    accountName:"Công ty TNHH Dịch Vụ CoDev",
    branch:"Quận 9", content:"PAY " + String(userAdmin.partner_id)});
    }
    else
    {
      setBank({name:"Ngân hàng TMCP Quân đội",
      bin:"970422",
      accountNumber:"8668393939",
      accountName:"TRẦN DUY PHONG",
      branch:"Quận 9", content:"PAY " + String(userAdmin.partner_id)});
    }
  }, [userAdmin]);

  const getLinkQRCode = (m)=>{
    return `https://img.vietqr.io/image/${bank.bin}-${bank.accountNumber}-compact.jpg?amount=${m}&addInfo=${bank.content}&accountName=${bank.accountName}`;
  }
  return (
    <>
      <HeaderCompany name="Nạp tiền vào tài khoản" parentName="Edited" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Nạp tiền vào tài khoản</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    {/* --------------title--------- */}
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="money"
                      >
                        Nhập số tiền cần nạp
                      </label>
                      <Input
                        id="money"
                        placeholder="Nhập số tiền cần nạp"
                        type="number"
                        defaultValue={money}
                        onChange={(e) => setMoney(e.target.value)}
                      />
                    </FormGroup>
                    <Row>
                      {bank ? (
                        <>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label">Thông tin chuyển khoản</label>
                          <p>Ngân hàng: <b className="txt-red">{bank.name}</b></p>
                          <p>Số tài khoản: <b className="txt-red">{bank.accountNumber}</b></p>
                          <p>Chủ tài khoản: <b className="txt-red">{bank.accountName}</b></p>
                          <p>Chi nhánh: <b className="txt-red">{bank.branch}</b></p>
                          <p>Số tiền: <b className="txt-red">{handleBalanceVND(Number(money))}</b></p>
                          <p>Nội dung chuyển khoản: <b className="txt-red">{bank.content}</b></p>
                        </FormGroup>
                      </Col>
                      
                      <Col lg="6">
                        <label className="form-control-label">Mã QRCode chuyển khoản nhanh</label>
                        <p><img src={getLinkQRCode(money)} width="250" /></p>
                      </Col>
                      </>
                      ) : (<></>)}
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Payment;
