import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import HeaderCompany from "components/Headers/HeaderCompany.js";
// react plugin that creates text editor
import Dropzone from "dropzone";
import {handleBalanceVND} from "../../../utils/helper";
import { getAdminData } from "redux/slices/adminSlice";
import { useSelector } from "react-redux";

Dropzone.autoDiscover = false;

const Payment = () => {
  let userAdmin = useSelector(getAdminData);

  return (
    <>
      <HeaderCompany name="Thông tin hoá đơn điện tử" parentName="Edited" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Thông tin hoá đơn điện tử</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    {/* --------------title--------- */}
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label">Chức năng sẽ được cập nhật trong thời gian tới</label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Payment;
