import { useSelector } from "react-redux";
import { getAdminData } from "../redux/slices/adminSlice";

const useAuth = () => {
  const adminData = useSelector(getAdminData);
  const token = adminData?.token_login;
  const expiration_time = adminData?.token_expiration_time;
  const isExpired = new Date(expiration_time).getTime() > new Date().getTime() ? true : false;

  if (token && !isExpired) return true;
  return adminData ? true : false;
};

export default useAuth;
