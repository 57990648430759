import { TRANSACTION_STATUS } from "./constants";

export const handleBalanceVND = (amount) => {
  const balanceFix = amount.toLocaleString("it-IT", {
    style: "currency",
    currency: "VND",
  });
  return balanceFix;
};

export const getStatusFromKey = (key) => {
  return TRANSACTION_STATUS[key];
};
