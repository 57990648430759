import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";
import Api from "components/api/api";
import { handleBalanceVND } from "utils/helper";

const CardsHeader = ({ name, parentName }) => {
  let userAdmin = useSelector(getAdminData);
  const [data, setData] = useState(null);

  useEffect(() => {
    loadData();
  }, [userAdmin]);

  const loadData = async () => {
    const reqUrl = "/partner/get-info-dashboard";
    const params = { token_login: userAdmin.token_login, email: userAdmin.email };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      console.log("loadData dashboard", res.data);
      const per_today = res.data.total_today > 0 ? (res.data.done_today / res.data.total_today) * 100 : 100;
      const per_month = res.data.total_month > 0 ? (res.data.done_month / res.data.total_month) * 100 : 100;
      setData({ ...res.data, per_today: per_today, per_month: per_month });
    }
  };

  return (
    <>
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">{name}</h6>{" "}
                <Breadcrumb className="d-none d-md-inline-block ml-md-4" listClassName="breadcrumb-links breadcrumb-dark">
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>

            <Row>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          Tiền nạp trong tháng
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{data ? handleBalanceVND(data.payment_month) : 0}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                          <i className="ni ni-money-coins" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">{data ? handleBalanceVND(data.payment_today) : 0}</span> <span className="text-nowrap">Nạp trong ngày</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          DS TOPUP trong tháng
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{data ? handleBalanceVND(data.topup_month) : 0}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <i className="ni ni-chart-pie-35" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">{data ? handleBalanceVND(data.topup_today) : 0}</span> <span className="text-nowrap">Trong hôm nay</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          DS Mã thẻ trong tháng
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{data ? handleBalanceVND(data.card_month) : 0}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <i className="ni ni-money-coins" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">{data ? handleBalanceVND(data.card_today) : 0}</span> <span className="text-nowrap">Trong hôm nay</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          Tỉ lệ thành công trong tháng
                        </CardTitle>
                        <span className={data && data.per_month > 90 ? "text-success mr-2" : "text-warning mr-2"}>
                          {data && data.per_today > 90 ? <i className="fa fa-arrow-up" /> : <i className="fa fa-arrow-down" />} {data ? data.per_month : 0}%
                        </span>{" "}
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                          <i className="ni ni-chart-bar-32" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className={data && data.per_today > 90 ? "text-success mr-2" : "text-warning mr-2"}>
                        {data && data.per_today > 90 ? <i className="fa fa-arrow-up" /> : <i className="fa fa-arrow-down" />} {data ? data.per_today : 0}%
                      </span>{" "}
                      <span className="text-nowrap">Tỉ lệ thành công hôm nay</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
export default CardsHeader;
