import ReactDOM from "react-dom/client";
// react library for routing
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
// bootstrap rtl for rtl support page
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
// core styles
import ProtectedLogin from "components/auth/ProtectedLogin";
import ProtectedLogout from "components/auth/ProtectedLogout";
import ProtectedRoute from "components/auth/ProtectedRoute";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import Dashboard from "views/pages/dashboards/Dashboard";
import EditPage from "views/pages/editpage";
import HistoryPayment from "views/pages/history_payment";
import HistoryTransaction from "views/pages/history_transaction";
import DiscountForPartner from "views/pages/discount_for_partner";
import HomeInformation from "views/pages/home_information";
import Login from "views/pages/login/Login";
import Payment from "views/pages/payment";
import HuongDanKetNoiPage from "views/pages/huong-dan-ket-noi";
import TranCuuHoaDonDienTu from "views/pages/invoice";

import { persistor, store } from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
const routes = createBrowserRouter([
  {
    path: "admin/*",
    element: <ProtectedRoute />,
    children: [
      { element: <Navigate to="/admin/information" />, index: true },
      {
        path: "information",
        element: <AdminLayout childrens={<HomeInformation />} />,
      },
      {
        path: "dashboard",
        element: <AdminLayout childrens={<Dashboard />} />,
      },
      {
        path: "payment",
        element: <AdminLayout childrens={<Payment />} />,
      },
      {
        path: "history-payment",
        element: <AdminLayout childrens={<HistoryPayment />} />,
      },
      {
        path: "history-transaction",
        element: <AdminLayout childrens={<HistoryTransaction />} />,
      },
      {
        path: "discount",
        element: <AdminLayout childrens={<DiscountForPartner />} />,
      },
      {
        path: "huong-dan-ket-noi",
        element: <AdminLayout childrens={<HuongDanKetNoiPage />} />,
      },
      {
        path: "invoice",
        element: <AdminLayout childrens={<TranCuuHoaDonDienTu />} />,
      },
      {
        path: "logout",
        element: <ProtectedLogout />,
      },
    ],
  },
  {
    path: "/",
    children: [
      { element: <Navigate to="/login" />, index: true },
      {
        path: "/login",
        element: <AuthLayout childrens={<ProtectedLogin props={<Login />} />} />,
      },
    ],
  },
]);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ToastContainer limit={3} />
      <RouterProvider router={routes} />
    </PersistGate>
  </Provider>
);
