import HeaderCompany from "components/Headers/HeaderCompany.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import Select2 from "react-select2-wrapper";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Input,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
} from "reactstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";
import Api from "components/api/api";
import { handleBalanceVND, getStatusFromKey } from "utils/helper";
import { format } from "date-fns";
import ToastProvider from "hooks/useToastProvider";

const HistoryTransaction = () => {
  let userAdmin = useSelector(getAdminData);

  const [dataTable, setDataTable] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [totalItem, setTotalItem] = useState(16);
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [status, setStatus] = useState("tat-ca");
  const [service, setService] = useState(0);
  const [request_partner_id, setRequestPartnerID] = useState("");
  const [fieldSort, setFieldSort] = useState("id");
  const [bySort, setBySort] = useState("asc");
  const [datePopup, setDataPopup] = useState(null);
  const [openModalDetail, setOpenModalDetail] = useState(false);

  const [isExporting, setIsExporting] = useState(false);

  const [summary, setSummary] = useState({
    tong_doanh_so: 0,
    tong_tien_giao_dich: 0,
    tong_giao_dich: 0,
    tong_giao_dich_thanh_cong: 0,
    tong_giao_dich_that_bai: 0,
    tong_giao_dich_dang_chay: 0,
    tong_giao_dich_loi: 0,
  });

  const columns = [
    {
      dataField: "time_created",
      text: "Thời gian khởi tạo",
      sort: true,
      formatter: (cell, row) => `${new Date(cell).toLocaleString("fr-BE")}`,
    },
    {
      dataField: "time_done",
      text: "Thời gian kết thúc",
      sort: true,
      formatter: (cell, row) => `${new Date(cell).toLocaleString("fr-BE")}`,
    },
    {
      dataField: "transaction_id",
      text: "Mã giao dịch",
      sort: true,
    },
    {
      dataField: "service",
      text: "Dịch vụ",
      sort: true,
    },
    {
      dataField: "content",
      text: "Sản phẩm",
      sort: true,
    },
    {
      dataField: "request_partner_id",
      text: "Mã giao dịch đối tác",
      sort: true,
    },
    {
      dataField: "chiet_khau_partner",
      text: "Chiết khấu",
      sort: true,
      formatter: (cell, row) => cell.toLocaleString() + "%",
    },
    {
      dataField: "money_sell",
      text: "Thành tiền (VND)",
      sort: true,
      formatter: (cell, row) => handleBalanceVND(cell),
    },
    {
      dataField: "status",
      text: "Trạng thái",
      sort: true,
      formatter: (cell, row) => (
        <span className={getStatusFromKey(cell).class}>
          {getStatusFromKey(cell).name}
        </span>
      ),
    },
    {
      dataField: "id",
      text: "Action",
      formatter: (cell, row) => (
        <Button
          className="btn-icon ml-2 form-control"
          color="primary"
          type="button"
          onClick={() => {
            onClickShowDetail(cell);
          }}
        >
          <span className="btn-inner--text">Detail</span>
        </Button>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: fieldSort,
      order: bySort,
    },
  ];

  useEffect(() => {
    console.log("useEffect 1");
    getListHistory();
  }, []);

  useEffect(() => {
    setDataTable([]);
    //console.log("useEffect 2");
    getListHistory();
  }, [pageCurrent, sizePage, fieldSort, bySort]);

  const getListHistory = async () => {
    const reqUrl = "/partner/get-history-transaction";
    const params = {
      token_login: userAdmin.token_login,
      email: userAdmin.email,
      page: pageCurrent,
      pageSize: sizePage,
      start_date: startDate,
      end_date: endDate,
      status: status,
      service: service,
      request_partner_id: request_partner_id,
      fieldSort: fieldSort,
      bySort: bySort,
    };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      //console.log("getListHistory", res.data.data);
      setDataTable(res.data.data.data);
      setTotalItem(res.data.data.pagination.total);
      if (pageCurrent == 1) {
        setSummary(res.data.summary);
      }
    }
  };

  const onSearchChange = () => {
    console.log("onSearchChange");
    setDataTable([]);
    getListHistory();
  };

  const onTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    const currentIndex = (page - 1) * sizePerPage;
    console.log(
      "onTableChange",
      type,
      page,
      sizePage,
      filters,
      sortField,
      sortOrder
    );
    if (type == "pagination") {
      setPageCurrent(page);
      setSizePage(sizePage);
    } else if (type == "sort") {
      setFieldSort(sortField);
      setBySort(sortOrder);
    }
  };

  const toggleModel = () => {
    setOpenModalDetail(!openModalDetail);
  };

  const onClickShowDetail = (id) => {
    let tmp = null;
    for (const it of dataTable) {
      if (it.id == id) {
        tmp = it;
        break;
      }
    }
    if (tmp) {
      setDataPopup(tmp);
      setOpenModalDetail(true);
    } else {
      ToastProvider("error", "Không có data của lịch sử này");
    }
  };

  const onExportExcel = async () => {
    setIsExporting(true);

    const reqUrl = "/partner/export-history-transaction";
    const params = {
      token_login: userAdmin.token_login,
      email: userAdmin.email,
      page: pageCurrent,
      pageSize: sizePage,
      start_date: startDate,
      end_date: endDate,
      status: status,
      service: service,
      request_partner_id: request_partner_id,
      fieldSort: fieldSort,
      bySort: bySort,
      type: 2,
    };
    try {
      const res = await Api.post(reqUrl, params);
      //console.log("onExportExcel", res.data.data);
      if (res.data.status == true) {
        setIsExporting(false);
        const link = document.createElement("a");
        link.href = res.data.data.url;
        link.setAttribute("download", res.data.data.name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        setIsExporting(false);
        ToastProvider("error", res.data.message);
      }
    } catch (error) {
      setIsExporting(false);
      ToastProvider(
        "error",
        "Có lỗi xảy ra khi export. Bạn có thể export với thời gian ngắn hơn"
      );
    }
  };

  const onExportExcelBBDS = async () => {
    setIsExporting(true);

    const reqUrl = "/partner/export-history-transaction";
    const params = {
      token_login: userAdmin.token_login,
      email: userAdmin.email,
      page: pageCurrent,
      pageSize: sizePage,
      start_date: startDate,
      end_date: endDate,
      status: status,
      service: service,
      request_partner_id: request_partner_id,
      fieldSort: fieldSort,
      bySort: bySort,
      type: 1,
    };
    try {
      const res = await Api.post(reqUrl, params);
      //console.log("onExportExcel", res.data.data);
      if (res.data.status == true) {
        setIsExporting(false);
        const link = document.createElement("a");
        link.href = res.data.data.url;
        link.setAttribute("download", res.data.data.name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        setIsExporting(false);
        ToastProvider("error", res.data.message);
      }
    } catch (error) {
      setIsExporting(false);
      ToastProvider(
        "error",
        "Có lỗi xảy ra khi export. Bạn có thể export với thời gian ngắn hơn"
      );
    }
  };
  return (
    <>
      <HeaderCompany name="Manage Partner" parentName="Manage Partner" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 className="mb-0">Lịch sử giao dịch</h3>
                {isExporting ? (
                  <p>Hệ thống đang export</p>
                ) : (
                  <>
                    <Button
                      className="buttons-copy buttons-html5"
                      color="default"
                      size="sm"
                      id="copy-tooltip"
                      onClick={onExportExcel}
                    >
                      <span>Export Detail</span>
                    </Button>
                    <Button
                      className="buttons-copy buttons-html5"
                      color="default"
                      size="sm"
                      id="copy-tooltip"
                      onClick={onExportExcelBBDS}
                    >
                      <span>Export BBDS</span>
                    </Button>

                    <UncontrolledTooltip
                      placement="bottom"
                      autohide={true}
                      target="copy-tooltip"
                    >
                      This will export to your clipboard the visible rows of the
                      table.
                    </UncontrolledTooltip>
                  </>
                )}
              </CardHeader>
              <CardBody>
                <div className="py-4 table-responsive">
                  <Row className="mb-5">
                    <Col md="6" xl="2">
                      <Card className="card-stats card_custom">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Tổng doanh số
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {summary.tong_doanh_so.toLocaleString()}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md="6" xl="2">
                      <Card className="card-stats card_custom">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Tổng tiền GD
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {summary.tong_tien_giao_dich.toLocaleString()}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md="6" xl="2">
                      <Card className="card-stats card_custom">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Tổng GD
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {summary.tong_giao_dich.toLocaleString()}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md="6" xl="2">
                      <Card className="card-stats card_custom">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                GD Thành công
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {summary.tong_giao_dich_thanh_cong.toLocaleString()}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md="6" xl="2">
                      <Card className="card-stats card_custom">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                GD Thất bại
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {summary.tong_giao_dich_that_bai.toLocaleString()}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md="6" xl="2">
                      <Card className="card-stats card_custom">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                GD Đang chạy
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {summary.tong_giao_dich_dang_chay.toLocaleString()}
                              </span>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-5">
                    <Col lg="2">
                      <label
                        className="form-control-label"
                        htmlFor="start_date"
                      >
                        Từ ngày
                      </label>
                      <Input
                        defaultValue={startDate}
                        id="start_date"
                        type="date"
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="end_date">
                        Đến ngày
                      </label>
                      <Input
                        defaultValue={endDate}
                        id="end_date"
                        type="date"
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="end_date">
                        Trạng thái
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn trạng thái",
                        }}
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        defaultValue={status}
                        data={[
                          { id: "tat-ca", text: "Tất cả" },
                          { id: "moi-tao", text: "Mới tạo" },
                          { id: "dang-xy-ly", text: "Đang xử lý" },
                          { id: "thanh-cong", text: "Thành công" },
                          {
                            id: "thanh-cong-missing",
                            text: "Thành công thiếu tiền",
                          },
                          { id: "time-out", text: "Time out" },
                          { id: "that-bai", text: "Thất bại" },
                          { id: "loi", text: "Giao dịch lỗi" },
                          { id: "hoan-tien", text: "Đã hoàn tiền" },
                        ]}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="end_date">
                        Loại dịch vụ
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn Dịch vụ",
                        }}
                        value={service}
                        onChange={(e) => setService(e.target.value)}
                        defaultValue={service}
                        data={[
                          { id: 0, text: "Tất cả" },
                          { id: 1, text: "Thẻ Cào" },
                          { id: 2, text: "TOPUP" },
                        ]}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="end_date">
                        Mã giao dịch / Mã giao dịch đối tác
                      </label>
                      <Input
                        defaultValue={request_partner_id}
                        id="request_partner_id"
                        type="text"
                        onChange={(e) => setRequestPartnerID(e.target.value)}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="btn">
                        &nbsp;
                      </label>
                      <Button
                        className="btn-icon ml-2 form-control"
                        color="primary"
                        type="button"
                        onClick={onSearchChange}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-bag-17" />
                        </span>
                        <span className="btn-inner--text">Search</span>
                      </Button>
                    </Col>
                  </Row>
                  {dataTable != null && dataTable.length > 0 ? (
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={dataTable}
                      columns={columns}
                      defaultSorted={defaultSorted}
                      filter={filterFactory()}
                      pagination={paginationFactory({
                        page: pageCurrent,
                        sizePerPage: sizePage,
                        totalSize: totalItem,
                      })}
                      onTableChange={onTableChange}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal isOpen={openModalDetail} backdrop="static">
        {datePopup ? (
          <>
            <ModalHeader>
              Chi tiết giao dịch - {datePopup.request_partner_id}
            </ModalHeader>
            <ModalBody>
              <table
                id="tblHistoryTransaction"
                className="table table-noborder table-nowrap popup-table"
              >
                <tbody>
                  <tr>
                    <td>Thời gian bắt đầu:</td>
                    <td>
                      {new Date(datePopup.time_created).toLocaleString("fr-BE")}
                    </td>
                  </tr>
                  <tr>
                    <td>Thời gian kết thúc:</td>
                    <td>
                      {new Date(datePopup.time_done).toLocaleString("fr-BE")}
                    </td>
                  </tr>
                  <tr>
                    <td>Mã giao dịch:</td>
                    <td>{datePopup.transaction_id}</td>
                  </tr>
                  <tr>
                    <td>Mã giao dịch của đối tác:</td>
                    <td>{datePopup.request_partner_id}</td>
                  </tr>
                  <tr>
                    <td>Sản phẩm:</td>
                    <td>{datePopup.service + " " + datePopup.telco}</td>
                  </tr>
                  <tr>
                    <td>Mệnh giá:</td>
                    <td>{datePopup.menh_gia} đ</td>
                  </tr>
                  <tr>
                    <td>Số lượng:</td>
                    <td>{datePopup.amount}</td>
                  </tr>
                  <tr>
                    <td>Chiết khấu:</td>
                    <td>{datePopup.chiet_khau_partner}%</td>
                  </tr>
                  <tr>
                    <td>Giảm giá:</td>
                    <td>
                      {handleBalanceVND(
                        datePopup.menh_gia_money *
                          datePopup.amount *
                          (datePopup.chiet_khau_partner / 100)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Phí giao dịch:</td>
                    <td> Miễn Phí </td>
                  </tr>
                  <tr>
                    <td>Thành tiền:</td>
                    <td>{handleBalanceVND(datePopup.money_sell)}</td>
                  </tr>
                  <tr>
                    <td>Phí tiện ích thanh toán:</td>
                    <td> Miễn Phí </td>
                  </tr>
                  <tr>
                    <td>Tổng thanh toán:</td>
                    <td>
                      <span className="txt-red">
                        {handleBalanceVND(datePopup.money_sell)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Số tiền trước khi mua:</td>
                    <td>
                      <span className="txt-green">
                        {handleBalanceVND(datePopup.partner_balance_truoc)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Số tiền sau khi mua:</td>
                    <td>
                      <span className="txt-green">
                        {handleBalanceVND(datePopup.partner_balance_sau)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Trạng thái:</td>
                    <td>
                      <span
                        className={getStatusFromKey(datePopup.status).class}
                      >
                        {getStatusFromKey(datePopup.status).name}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModel}>
                Close
              </Button>
            </ModalFooter>
          </>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

export default HistoryTransaction;
