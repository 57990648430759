const routes = [
  {
    collapse: true,
    name: "Tài Khoản",
    icon: "ni ni-single-02 text-primary",
    state: "taiKhoan",
    views: [
      {
        path: "/dashboard",
        name: "Dashboard",
        miniName: "T",
        layout: "/admin",
      },
      {
        path: "/information",
        name: "Thông Tin Tài Khoản",
        miniName: "T",
        layout: "/admin",
      },
      {
        path: "/discount",
        name: "Danh sách chiết khấu",
        miniName: "T",
        layout: "/admin",
      },
      {
        path: "/payment",
        name: "Nạp tiền",
        miniName: "N",
        layout: "/admin",
      },
      {
        path: "/history-payment",
        name: "Lịch sử nạp tiền",
        miniName: "N",
        layout: "/admin",
      },
      {
        path: "/history-transaction",
        name: "Lịch sử giao dịch",
        miniName: "N",
        layout: "/admin",
      },
      {
        path: "/huong-dan-ket-noi",
        name: "Hướng dẫn kết nối",
        miniName: "D",
        layout: "/admin",
      },
      {
        path: "/invoice",
        name: "Tra cứu hóa đơn điện tử",
        miniName: "D",
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Logout",
    icon: "ni ni-chart-pie-35 text-primary",
    state: "logout",
    views: [
      {
        path: "/logout",
        name: "Logout",
        miniName: "N",
        layout: "/admin",
      },
    ],
  },
];

export default routes;
