import HeaderCompany from "components/Headers/HeaderCompany.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import Select2 from "react-select2-wrapper";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";
import Api from "components/api/api";
import { handleBalanceVND, getStatusFromKey } from "utils/helper";
import { format } from "date-fns";
import ToastProvider from "hooks/useToastProvider";

const DiscountForPartner = () => {
  let userAdmin = useSelector(getAdminData);

  const [dataTable, setDataTable] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [totalItem, setTotalItem] = useState(16);
  const [telco, setTelco] = useState(0);
  const [service, setService] = useState(0);
  const [fieldSort, setFieldSort] = useState("id");
  const [bySort, setBySort] = useState("asc");

  const columns = [{
    dataField: 'id',
    text: 'ID',
    sort: true,
  }, {
    dataField: 'partner',
    text: 'Partner',
    sort: true,
  }, {
    dataField: 'telco',
    text: 'Telco',
    sort: true,
  }, {
    dataField: 'service',
    text: 'Dịch vụ',
    sort: true,
  }, {
    dataField: 'menh_gia',
    text: 'Mệnh giá',
    sort: true,
  }, {
    dataField: 'chietkhau',
    text: 'CK',
    sort: true,
  }, {
    dataField: 'createdAt',
    text: 'Ngày tạo',
    sort: true,
    formatter: (cell, row) => (
      `${(new Date(cell)).toLocaleString('fr-BE')}`
    )
  }, {
    dataField: 'status',
    text: 'Trạng thái',
    sort: true,
    formatter: (cell) => (
      <Button
        className="btn-icon d-flex"
        size="sm"
        color="info"
        type="button"
        outline
      >
        <span className="btn-inner--text">{cell == 1 ? "Active" : "Pending"}</span>
      </Button>
    )
  }
];

  const defaultSorted = [
    {
      dataField: fieldSort,
      order: bySort,
    },
  ];

  useEffect(() => {
    console.log("useEffect 1");
    getListHistory();
  }, []);

  useEffect(() => {
    setDataTable([]);
    console.log("useEffect 2");
    getListHistory();
  }, [pageCurrent, sizePage, fieldSort, bySort]);

  const getListHistory = async () => {
    const reqUrl = "/partner/get-list-chiet-khau-partner";
    const params = {
      token_login: userAdmin.token_login,
      email: userAdmin.email,
      page: pageCurrent,
      pageSize: sizePage,
      telco: Number(telco),
      service: Number(service),
      fieldSort: fieldSort,
      bySort: bySort,
    };
    const res = await Api.post(reqUrl, params);
    if (res.data.status == true) {
      //console.log("getListHistory", res.data.data);
      setDataTable(res.data.data.data);
      setTotalItem(res.data.data.pagination.total);
    }
  };

  const onSearchChange = () => {
    console.log("onSearchChange");
    setDataTable([]);
    getListHistory();
  };

  const onTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    const currentIndex = (page - 1) * sizePerPage;
    console.log(
      "onTableChange",
      type,
      page,
      sizePage,
      filters,
      sortField,
      sortOrder
    );
    if (type == "pagination") {
      setPageCurrent(page);
      setSizePage(sizePage);
    } else if (type == "sort") {
      setFieldSort(sortField);
      setBySort(sortOrder);
    }
  };

  return (
    <>
      <HeaderCompany name="Manage Partner" parentName="Manage Partner" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 className="mb-0">Danh sách chiết khấu dành cho đối tác</h3>
              </CardHeader>
              <CardBody>
                <div className="py-4 table-responsive">
                  <Row className="mb-5">
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="end_date">
                        Loại dịch vụ
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn Dịch vụ",
                        }}
                        value={service}
                        onChange={(e) => setService(e.target.value)}
                        defaultValue={service}
                        data={[
                          { id: 0, text: "Tất cả" },
                          { id: 1, text: "Thẻ Cào" },
                          { id: 2, text: "TOPUP" },
                        ]}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="end_date">
                        Nhà mạng
                      </label>
                      <Select2
                        className={`form-control`}
                        options={{
                          placeholder: "Chọn nhà mạng",
                        }}
                        value={telco}
                        onChange={(e) => setTelco(e.target.value)}
                        defaultValue={telco}
                        data={[
                          { id: 0, text: "Tất cả" },
                          { id: 1, text: "Mobifone" },
                          { id: 2, text: "Viettel" },
                          { id: 3, text: "Vinaphone" },
                        ]}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="btn">
                        &nbsp;
                      </label>
                      <Button
                        className="btn-icon ml-2 form-control"
                        color="primary"
                        type="button"
                        onClick={onSearchChange}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-bag-17" />
                        </span>
                        <span className="btn-inner--text">Search</span>
                      </Button>
                    </Col>
                  </Row>
                  {dataTable != null && dataTable.length > 0 ? (
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={dataTable}
                      columns={columns}
                      defaultSorted={defaultSorted}
                      filter={filterFactory()}
                      pagination={paginationFactory({
                        page: pageCurrent,
                        sizePerPage: sizePage,
                        totalSize: totalItem,
                      })}
                      onTableChange={onTableChange}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DiscountForPartner;
