import HeaderCompany from "components/Headers/HeaderCompany.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from 'react-bootstrap-table2-filter';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAdminData } from "redux/slices/adminSlice";
import Api from "components/api/api";
import { handleBalanceVND } from "utils/helper";
import { format } from 'date-fns'

const HistoryPayment = () => {  
  let userAdmin = useSelector(getAdminData);

  const [dataTable, setDataTable] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [totalItem, setTotalItem] = useState(16);
  const [startDate, setStartDate] = useState((new Date()).toLocaleDateString("en-CA"));
  const [endDate, setEndDate] = useState((new Date()).toLocaleDateString("en-CA"));
  const [fieldSort, setFieldSort] = useState("id");
  const [bySort, setBySort] = useState("asc");

  const columns=[
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "money",
      text: "Tiền nạp",
      sort: true,
      formatter: (cell, row) => (
        handleBalanceVND(cell)
      )
    },
    {
      dataField: "balance_before",
      text: "Balance trước khi nạp",
      sort: true,
      formatter: (cell, row) => (
        handleBalanceVND(cell)
      )
    },
    {
      dataField: "balance_after",
      text: "Balance sau khi nạp",
      sort: true,
      formatter: (cell, row) => (
        handleBalanceVND(cell)
      )
    },
    {
      dataField: "date_pay",
      text: "Thời gian nạp",
      sort: true,
      formatter: (cell, row) => (
        `${(new Date(cell)).toLocaleString('fr-BE')}`
      ),
    },
    {
      dataField: "log",
      text: "Log",
      sort: true,
    },
  ];

  const defaultSorted = [{
    dataField: fieldSort,
    order: bySort
  }];

  useEffect(()=>{
    getListHistory();
  }, []);

  useEffect(()=>{
    setDataTable([]);
    getListHistory();
  }, [pageCurrent, sizePage, fieldSort, bySort]);
  
  const getListHistory = async ()=>{
    const reqUrl = "/partner/get-history-payment";
    const params = {token_login:userAdmin.token_login, page:pageCurrent, pageSize:sizePage, start_date:startDate, end_date:endDate, fieldSort:fieldSort, bySort:bySort, email:userAdmin.email};
    const res = await Api.post(reqUrl, params);
    if(res.data.status == true)
    {
      console.log("res.data.data", res.data.data);
      setDataTable(res.data.data.data);
      setTotalItem(res.data.data.pagination.total);
    }
  }

  const onSearchChange = () =>{
    console.log("onSearchChange");
    setDataTable([]);
    getListHistory();
  }

  const onTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder }) => {
    const currentIndex = (page - 1) * sizePerPage;
    console.log("onTableChange", type, page, sizePage, filters, sortField, sortOrder);
    if(type == "pagination")
    {
      setPageCurrent(page);
      setSizePage(sizePage);
    }
    else if(type == "sort")
    {
      setFieldSort(sortField);
      setBySort(sortOrder);
    }
  }

  return (
    <>
      <HeaderCompany name="Manage Partner" parentName="Manage Partner" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Lịch sử nạp tiền</h3>
              </CardHeader>
              <CardBody>
                    <div className="py-4 table-responsive">
                      <Row className="mb-5">
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="start_date"
                          >
                            Từ ngày
                          </label>
                          <Input
                            defaultValue={startDate}
                            id="start_date"
                            type="date"
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </Col>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="end_date"
                          >
                            Đến ngày
                          </label>
                          <Input
                            defaultValue={endDate}
                            id="end_date"
                            type="date"
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </Col>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="btn"
                          >
                            &nbsp;
                          </label>
                          <Button
                              className="btn-icon ml-2 form-control"
                              color="primary"
                              type="button"
                              onClick={onSearchChange}
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="ni ni-bag-17" />
                              </span>
                              <span className="btn-inner--text">Search</span>
                            </Button>
                        </Col>
                      </Row>
                      {dataTable != null && dataTable.length > 0 ? (
                      <BootstrapTable
                        remote
                        keyField="id"
                        data={ dataTable }
                        columns={ columns }
                        defaultSorted={ defaultSorted }
                        filter={ filterFactory() }
                        pagination={ paginationFactory({page: pageCurrent, sizePerPage: sizePage, totalSize: totalItem }) }
                        onTableChange={ onTableChange }
                      />
                      ) : (
                      <></>
                      )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default HistoryPayment;
