export const TRANSACTION_STATUS = {
  "moi-tao": { name: "Mới khởi tạo", class: "txt-yellow" },
  "dang-xy-ly": { name: "Đang xử lý", class: "txt-yellow" },
  "thanh-cong": { name: "Thành công", class: "txt-green" },
  "thanh-cong-missing": { name: "Thành công nhưng nạp thiếu", class: "txt-green" },
  "time-out": { name: "Time out", class: "txt-red" },
  "that-bai": { name: "Thất bại", class: "txt-red" },
  loi: { name: "Giao dịch lỗi", class: "txt-red" },
  "hoan-tien": { name: "Đã hoàn tiền", class: "txt-blue" },
};
